import React from "react"
import { useIntl, FormattedMessage } from "gatsby-plugin-intl"
import Header from "../components/Header/Header"
import Footer from "../components/Footer/Footer"
import ScrollUp from "../components/ScrollUp"
import SEO from "../components/SEO"
import { StaticQuery, graphql } from "gatsby"

const getIframeLink = iframe => {
  let link = iframe.slice(iframe.indexOf("https"), iframe.indexOf("width"))
  link = link.slice(0, link.indexOf('"'))
  return link
}

export default function Yhteystiedot() {
  const intl = useIntl()
  return (
    <StaticQuery
      query={graphql`
        query ContactQuery {
          allSanityContact {
            nodes {
              Language
              person_list {
                email
                app {
                  icon
                  link
                }
                name
                phone
                position
                working_time
                mainImage {
                  asset {
                    fluid {
                      src
                    }
                  }
                }
              }
              form_title
              form_subtitle
              map_address
              subject
            }
          }
        }
      `}
      render={data => {
        let resource = null
        if (intl.locale === "en") {
          resource = data.allSanityContact.nodes.filter(
            item => item.Language === "en"
          )
          resource = resource[0]
          console.log(resource)
        } else {
          resource = data.allSanityContact.nodes.filter(
            item => item.Language === "fi"
          )
          resource = resource[0]
          console.log(resource)
        }
        return (
          <>
            {resource && (
              <>
                <SEO />
                <div className="wrapper">
                  <Header link="/yhteystiedot" />
                  <section className="team-layout1 pb-30">
                    <div className="container">
                      <div className="row">
                        {resource.person_list.map((item, index) => {
                          return (
                            <div
                              key={index}
                              className="col-sm-6 col-md-4 col-lg-4 profile-picture"
                            >
                              <div className="member">
                                <div className="member__img">
                                  <img
                                    src={item.mainImage.asset.fluid.src}
                                    alt="member img"
                                  />
                                  <div className="member__hover">
                                    <div className="member__content-inner">
                                      <ul className="social__icons justify-content-center list-unstyled mb-0">
                                        {item.app.map((item, index) => {
                                          return (
                                            <li key={index}>
                                              <a href={item.link}>
                                                <i className={item.icon} />
                                              </a>
                                            </li>
                                          )
                                        })}
                                      </ul>
                                    </div>
                                  </div>
                                </div>

                                <div className="member__info">
                                  <h5 className="member__name">{item.name}</h5>
                                  <p
                                    style={{ textDecoration: "underline" }}
                                    className="member__desc"
                                  >
                                    {item.position}
                                  </p>
                                  <ul className="contact__info-list list-unstyled">
                                    <li>
                                      <a href={`mailto:${item.email}`}>
                                        {item.email}
                                      </a>
                                    </li>
                                    <li>
                                      <a href={`tel:${item.phone}`}>
                                        {item.phone}
                                      </a>
                                    </li>
                                    <li>{item.working_time}</li>
                                  </ul>
                                </div>
                              </div>
                            </div>
                          )
                        })}
                      </div>
                    </div>
                  </section>

                  {/* <section
                style={{ paddingBottom: 0 }}
                className="contact-layout pt-0"
              >
                <div className="container col-padding-0">
                  <div className="row contact-panel">
                    <form
                      className="contact-panel__form"
                      method="post"
                      action="assets/php/contact.php"
                      id="contactForm"
                    >
                      <div className="row">
                        <div className="col-sm-12">
                          <h4 className="contact-panel__title">
                            {resource.form_title}
                          </h4>
                          <p className="contact-panel__desc mb-40">
                            {resource.form_subtitle}
                          </p>
                        </div>
                        <div className="col-sm-6 col-md-6 col-lg-6">
                          <div className="form-group">
                            <input
                              type="text"
                              className="form-control"
                              placeholder="Nimi"
                              id="contact-name"
                              name="contact-name"
                              required
                            />
                          </div>
                        </div>

                        <div className="col-sm-6 col-md-6 col-lg-6">
                          <div className="form-group">
                            <input
                              type="email"
                              className="form-control"
                              placeholder="Sähköposti"
                              id="contact-email"
                              name="contact-email"
                              required
                            />
                          </div>
                        </div>

                        <div className="col-sm-6 col-md-6 col-lg-6">
                          <div className="form-group">
                            <input
                              type="text"
                              className="form-control"
                              placeholder="Puhelinnumero"
                              id="contact-Phone"
                              name="contact-phone"
                              required
                            />
                          </div>
                        </div>

                        <div className="col-sm-6 col-md-6 col-lg-6">
                          <div className="form-group">
                            <select className="form-control">
                              {resource.subject.map((item, index) => {
                                return (
                                  <option key={index} value={index}>
                                    {item}
                                  </option>
                                )
                              })}
                            </select>
                          </div>
                        </div>

                        <div className="col-sm-12 col-md-12 col-lg-12">
                          <div className="form-group">
                            <textarea
                              className="form-control"
                              placeholder="Viesti"
                              id="contact-messgae"
                              name="contact-messgae"
                              required
                              defaultValue={""}
                            />
                          </div>
                        </div>

                        <button
                          type="submit"
                          className="btn btn__primary ml-15"
                        >
                          Lähetä
                        </button>
                      </div>
                    </form>
                  </div>
                </div>
              </section> */}

                  <section className="google-map p-0 mt-50">
                    <div className="container">
                      <iframe
                        src={getIframeLink(resource.map_address)}
                        width="100%"
                        height={450}
                        style={{ border: 0 }}
                        allowFullScreen
                        loading="lazy"
                      />
                    </div>
                  </section>
                  <Footer />
                  <ScrollUp />
                </div>
              </>
            )}
          </>
        )
      }}
    />
  )
}
